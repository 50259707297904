<template>
  <ValidationObserver ref="formFilterValidation" v-slot="{ handleSubmit }">
    <b-container fluid class="p-0">
      <b-row>
        <b-col>
          <ValidationProvider
            name="період"
            v-slot="{ errors, dirty, validated, valid, changed }"
            vid="processed_at_gte"
          >
            <b-form-group
              label-size="sm"
              label-class="font-weight-bold m-0"
              label="За період"
            >
              <date-range-picker
                :start-date="propProcessedAtFrom"
                :end-date="propProcessedAtTo"
                v-on:start-date-change="changeProcessedAtFrom"
                v-on:end-date-change="changeProcessedAtTo"
                :state="
                  setValidationState(errors, dirty, validated, valid, changed)
                "
                style="width: 100%"
              />
              <b-form-invalid-feedback
                :state="errors.length === 0"
                v-for="(error, index) in errors"
                v-bind:key="index"
              >
                {{ error }}
              </b-form-invalid-feedback>
            </b-form-group>
          </ValidationProvider>
        </b-col>
        <b-col>
          <ValidationProvider
            name="ID операції в платіжній системі"
            v-slot="{ errors, dirty, validated, valid, changed }"
            vid="paysystem_operation_id_where"
            rules="max:255"
          >
            <b-form-group
              label-size="sm"
              label-class="font-weight-bold m-0"
              label="ID операції в платіжній системі"
            >
              <b-form-input
                :value="propPaysystemOperationId"
                @input="changeFilter('propPaysystemOperationId', $event)"
                trim
                :state="
                  setValidationState(errors, dirty, validated, valid, changed)
                "
              />
              <b-form-invalid-feedback
                :state="errors.length === 0"
                v-for="(error, index) in errors"
                v-bind:key="index"
              >
                {{ error }}
              </b-form-invalid-feedback>
            </b-form-group>
          </ValidationProvider>
        </b-col>
        <b-col>
          <ValidationProvider
            name="Дата завершення переказу"
            v-slot="{ errors, dirty, validated, valid, changed }"
            vid="received_at_gte"
          >
            <b-form-group
              label-size="sm"
              label-class="font-weight-bold m-0"
              label="Дата завершення переказу"
            >
              <date-range-picker
                :start-date="propReceivedAtFrom"
                :end-date="propReceivedAtTo"
                v-on:start-date-change="changeReceivedAtFrom"
                v-on:end-date-change="changeReceivedAtTo"
                :state="
                  setValidationState(errors, dirty, validated, valid, changed)
                "
                style="width: 100%"
              />
              <b-form-invalid-feedback
                :state="errors.length === 0"
                v-for="(error, index) in errors"
                v-bind:key="index"
              >
                {{ error }}
              </b-form-invalid-feedback>
            </b-form-group>
          </ValidationProvider>
        </b-col>
        <b-col>
          <ValidationProvider
            name="ID операції"
            v-slot="{ errors, dirty, validated, valid, changed }"
            vid="id_where"
            :rules="{
              regex: $stringConstants('REGEX_UUID'),
            }"
          >
            <b-form-group
              label-size="sm"
              label-class="font-weight-bold m-0"
              label="ID операції"
            >
              <b-form-input
                :value="propId"
                @input="changeFilter('propId', $event)"
                trim
                :state="
                  setValidationState(errors, dirty, validated, valid, changed)
                "
              />
              <b-form-invalid-feedback
                :state="errors.length === 0"
                v-for="(error, index) in errors"
                v-bind:key="index"
              >
                {{ error }}
              </b-form-invalid-feedback>
            </b-form-group>
          </ValidationProvider>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <ValidationProvider
            name="Профіль СПФМ"
            v-slot="{ errors, dirty, validated, valid, changed }"
            vid="financial_model_id_where"
            :rules="{
              regex: $stringConstants('REGEX_UUID'),
            }"
          >
            <b-form-group
              label-size="sm"
              label-class="font-weight-bold m-0"
              label="Профіль СПФМ"
            >
              <b-form-select
                text-field="name"
                value-field="id"
                :value="propFinancialModelId"
                @input="changeFilter('propFinancialModelId', $event)"
                :options="meFinancialModelList"
                :state="
                  setValidationState(errors, dirty, validated, valid, changed)
                "
              >
                <template #first>
                  <b-form-select-option value="" />
                </template>
              </b-form-select>
              <b-form-invalid-feedback
                :state="errors.length === 0"
                v-for="(error, index) in errors"
                v-bind:key="index"
              >
                {{ error }}
              </b-form-invalid-feedback>
            </b-form-group>
          </ValidationProvider>
        </b-col>
        <b-col>
          <ValidationProvider
            name="Платіжна система"
            v-slot="{ errors, dirty, validated, valid, changed }"
            vid="nbu_paysystem_code_where"
          >
            <b-form-group
              label-size="sm"
              label-class="font-weight-bold m-0"
              label="Платіжна система"
            >
              <b-form-select
                text-field="name"
                :value="propNbuPaysystemCode"
                @input="changeFilter('propNbuPaysystemCode', $event)"
                :options="operationNbuPaysystemList"
                :state="
                  setValidationState(errors, dirty, validated, valid, changed)
                "
              >
                <template #first>
                  <b-form-select-option value="" />
                </template>
              </b-form-select>
              <b-form-invalid-feedback
                :state="errors.length === 0"
                v-for="(error, index) in errors"
                v-bind:key="index"
              >
                {{ error }}
              </b-form-invalid-feedback>
            </b-form-group>
          </ValidationProvider>
        </b-col>
        <b-col>
          <ValidationProvider
            name="Платіжний метод"
            v-slot="{ errors, dirty, validated, valid, changed }"
            vid="operation_type_id_where_in"
          >
            <b-form-group
              label-size="sm"
              label-class="font-weight-bold m-0"
              label="Платіжний метод"
            >
              <multiselect
                :value="propOperationType"
                @input="changeFilter('propOperationType', $event)"
                :options="paymentMethodList.map((e) => e.id)"
                :multiple="true"
                :custom-label="customPaymentMethodLabel"
                :close-on-select="false"
                :clear-on-select="false"
                :preserve-search="true"
                :show-labels="false"
                placeholder=""
                :state="
                  setValidationState(errors, dirty, validated, valid, changed)
                "
              >
                <template class="checkbox-label" v-slot:option="scope">
                  <input
                    type="checkbox"
                    :checked="propOperationType.includes(scope.option)"
                    @focus.prevent
                  />
                  {{ customPaymentMethodLabel(scope.option) }}
                </template>
              </multiselect>
              <b-form-invalid-feedback
                :state="errors.length === 0"
                v-for="(error, index) in errors"
                v-bind:key="index"
              >
                {{ error }}
              </b-form-invalid-feedback>
            </b-form-group>
          </ValidationProvider>
        </b-col>
        <b-col>
          <ValidationProvider
            name="Призначення платежу"
            v-slot="{ errors, dirty, validated, valid, changed }"
            vid="operation_destination_code_id_where_in"
          >
            <b-form-group
              label-size="sm"
              label-class="font-weight-bold m-0"
              label="Призначення платежу"
            >
              <multiselect
                :value="propOperationDestinationCode"
                @input="changeFilter('propOperationDestinationCode', $event)"
                :options="[
                  {
                    all: 'Всі',
                    options: operationDestinationCodeList.map((e) => e.id),
                  },
                ]"
                :multiple="true"
                :close-on-select="false"
                :clear-on-select="false"
                :preserve-search="true"
                group-values="options"
                group-label="all"
                :group-select="true"
                :show-labels="false"
                placeholder=""
                :custom-label="customDestinationCodeLabel"
                :state="
                  setValidationState(errors, dirty, validated, valid, changed)
                "
              >
                <template class="checkbox-label" v-slot:option="scope">
                  <img
                    v-if="
                      !scope.option.hasOwnProperty('$groupLabel') &&
                      destinationCodeHasEndedAtDate(scope.option)
                    "
                    src="/images/text-help.svg"
                    alt="help"
                    style="margin-right: 10px"
                    v-b-tooltip
                    :title="tooltipDestinationCode(scope.option)"
                  />
                  <input
                    v-if="!scope.option.hasOwnProperty('$groupLabel')"
                    type="checkbox"
                    :checked="
                      propOperationDestinationCode.includes(scope.option)
                    "
                    @focus.prevent
                  />
                  <template v-if="scope.option.hasOwnProperty('$groupLabel')">
                    {{ scope.option.$groupLabel }}
                  </template>
                  <template v-else>
                    {{ customDestinationCodeLabel(scope.option) }}
                  </template>
                </template>
              </multiselect>
              <b-form-invalid-feedback
                :state="errors.length === 0"
                v-for="(error, index) in errors"
                v-bind:key="index"
              >
                {{ error }}
              </b-form-invalid-feedback>
            </b-form-group>
          </ValidationProvider>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <ValidationProvider
            name="Платник"
            v-slot="{ errors, dirty, validated, valid, changed }"
            vid="payer_ilike"
            rules="max:255"
          >
            <b-form-group
              label-size="sm"
              label-class="font-weight-bold m-0"
              label="Платник"
            >
              <b-form-input
                :value="propPayer"
                @input="changeFilter('propPayer', $event)"
                trim
                :state="
                  setValidationState(errors, dirty, validated, valid, changed)
                "
              />
              <b-form-invalid-feedback
                :state="errors.length === 0"
                v-for="(error, index) in errors"
                v-bind:key="index"
              >
                {{ error }}
              </b-form-invalid-feedback>
            </b-form-group>
          </ValidationProvider>
        </b-col>
        <b-col>
          <ValidationProvider
            name="Договір з платником"
            v-slot="{ errors, dirty, validated, valid, changed }"
            vid="payer_contract_number_where"
            rules="max:255"
          >
            <b-form-group
              label-size="sm"
              label-class="font-weight-bold m-0"
              label="Договір з платником"
            >
              <b-form-input
                :value="propPayerContractNumber"
                @input="changeFilter('propPayerContractNumber', $event)"
                trim
                :state="
                  setValidationState(errors, dirty, validated, valid, changed)
                "
              />
              <b-form-invalid-feedback
                :state="errors.length === 0"
                v-for="(error, index) in errors"
                v-bind:key="index"
              >
                {{ error }}
              </b-form-invalid-feedback>
            </b-form-group>
          </ValidationProvider>
        </b-col>
        <b-col>
          <ValidationProvider
            name="Отримувач"
            v-slot="{ errors, dirty, validated, valid, changed }"
            vid="payee_ilike"
            rules="max:255"
          >
            <b-form-group
              label-size="sm"
              label-class="font-weight-bold m-0"
              label="Отримувач"
            >
              <b-form-input
                :value="propPayee"
                @input="changeFilter('propPayee', $event)"
                trim
                :state="
                  setValidationState(errors, dirty, validated, valid, changed)
                "
              />
              <b-form-invalid-feedback
                :state="errors.length === 0"
                v-for="(error, index) in errors"
                v-bind:key="index"
              >
                {{ error }}
              </b-form-invalid-feedback>
            </b-form-group>
          </ValidationProvider>
        </b-col>
        <b-col>
          <ValidationProvider
            name="Договір з отримувачем"
            v-slot="{ errors, dirty, validated, valid, changed }"
            vid="payee_contract_number_where"
            rules="max:255"
          >
            <b-form-group
              label-size="sm"
              label-class="font-weight-bold m-0"
              label="Договір з отримувачем"
            >
              <b-form-input
                :value="propPayeeContractNumber"
                @input="changeFilter('propPayeeContractNumber', $event)"
                trim
                :state="
                  setValidationState(errors, dirty, validated, valid, changed)
                "
              />
              <b-form-invalid-feedback
                :state="errors.length === 0"
                v-for="(error, index) in errors"
                v-bind:key="index"
              >
                {{ error }}
              </b-form-invalid-feedback>
            </b-form-group>
          </ValidationProvider>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <ValidationProvider
            name="Платник ID"
            v-slot="{ errors, dirty, validated, valid, changed }"
            vid="payer_counterpart_id_where_in"
          >
            <b-form-group
              label-size="sm"
              label-class="font-weight-bold m-0"
              label="Платник ID"
            >
              <multiselect
                :value="propPayerCounterpartId"
                @input="changeFilter('propPayerCounterpartId', $event)"
                :options="[
                  {
                    all: 'Всі',
                    options: counterparts.map((e) => e.id),
                  },
                ]"
                :multiple="true"
                :close-on-select="false"
                :clear-on-select="false"
                :preserve-search="true"
                group-values="options"
                group-label="all"
                :group-select="true"
                :show-labels="false"
                placeholder=""
                :custom-label="customCounterpartNameLabel"
                :state="
                  setValidationState(errors, dirty, validated, valid, changed)
                "
              >
                <template class="checkbox-label" v-slot:option="scope">
                  <input
                    v-if="!scope.option.hasOwnProperty('$groupLabel')"
                    type="checkbox"
                    :checked="propPayerCounterpartId.includes(scope.option)"
                    @focus.prevent
                  />
                  <template v-if="scope.option.hasOwnProperty('$groupLabel')">
                    {{ scope.option.$groupLabel }}
                  </template>
                  <template v-else>
                    {{ customCounterpartNameLabel(scope.option) }}
                  </template>
                </template>
              </multiselect>
              <b-form-invalid-feedback
                :state="errors.length === 0"
                v-for="(error, index) in errors"
                v-bind:key="index"
              >
                {{ error }}
              </b-form-invalid-feedback>
            </b-form-group>
          </ValidationProvider>
        </b-col>

        <b-col>
          <ValidationProvider
            name="Платник (Маска картки)"
            v-slot="{ errors, dirty, validated, valid, changed }"
            vid="payer_where"
            rules="max:255"
          >
            <b-form-group
              label-size="sm"
              label-class="font-weight-bold m-0"
              label="Платник (Маска картки)"
            >
              <b-form-input
                :value="propPayerCardMask"
                @input="changeFilter('propPayerCardMask', $event)"
                trim
                :state="
                  setValidationState(errors, dirty, validated, valid, changed)
                "
              />
              <b-form-invalid-feedback
                :state="errors.length === 0"
                v-for="(error, index) in errors"
                v-bind:key="index"
              >
                {{ error }}
              </b-form-invalid-feedback>
            </b-form-group>
          </ValidationProvider>
        </b-col>

        <b-col>
          <ValidationProvider
            name="Отримувач ID"
            v-slot="{ errors, dirty, validated, valid, changed }"
            vid="payee_counterpart_id_where_in"
          >
            <b-form-group
              label-size="sm"
              label-class="font-weight-bold m-0"
              label="Отримувач ID"
            >
              <multiselect
                :value="propPayeeCounterpartId"
                @input="changeFilter('propPayeeCounterpartId', $event)"
                :options="[
                  {
                    all: 'Всі',
                    options: counterparts.map((e) => e.id),
                  },
                ]"
                :multiple="true"
                :close-on-select="false"
                :clear-on-select="false"
                :preserve-search="true"
                group-values="options"
                group-label="all"
                :group-select="true"
                :show-labels="false"
                placeholder=""
                :custom-label="customCounterpartNameLabel"
                :state="
                  setValidationState(errors, dirty, validated, valid, changed)
                "
              >
                <template class="checkbox-label" v-slot:option="scope">
                  <input
                    v-if="!scope.option.hasOwnProperty('$groupLabel')"
                    type="checkbox"
                    :checked="propPayeeCounterpartId.includes(scope.option)"
                    @focus.prevent
                  />
                  <template v-if="scope.option.hasOwnProperty('$groupLabel')">
                    {{ scope.option.$groupLabel }}
                  </template>
                  <template v-else>
                    {{ customCounterpartNameLabel(scope.option) }}
                  </template>
                </template>
              </multiselect>
              <b-form-invalid-feedback
                :state="errors.length === 0"
                v-for="(error, index) in errors"
                v-bind:key="index"
              >
                {{ error }}
              </b-form-invalid-feedback>
            </b-form-group>
          </ValidationProvider>
        </b-col>

        <b-col>
          <ValidationProvider
            name="Отримувач (Маска картки)"
            v-slot="{ errors, dirty, validated, valid, changed }"
            vid="payee_where"
            rules="max:255"
          >
            <b-form-group
              label-size="sm"
              label-class="font-weight-bold m-0"
              label="Отримувач (Маска картки)"
            >
              <b-form-input
                :value="propPayeeCardMask"
                @input="changeFilter('propPayeeCardMask', $event)"
                trim
                :state="
                  setValidationState(errors, dirty, validated, valid, changed)
                "
              />
              <b-form-invalid-feedback
                :state="errors.length === 0"
                v-for="(error, index) in errors"
                v-bind:key="index"
              >
                {{ error }}
              </b-form-invalid-feedback>
            </b-form-group>
          </ValidationProvider>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <ValidationProvider
            name="Хеш картки платника"
            v-slot="{ errors, dirty, validated, valid, changed }"
            vid="payer_card_hash_where"
            rules="max:255"
          >
            <b-form-group
              label-size="sm"
              label-class="font-weight-bold m-0"
              label="Хеш картки платника"
            >
              <b-form-input
                :value="propPayerCardHash"
                @input="changeFilter('propPayerCardHash', $event)"
                trim
                :state="
                  setValidationState(errors, dirty, validated, valid, changed)
                "
              />
              <b-form-invalid-feedback
                :state="errors.length === 0"
                v-for="(error, index) in errors"
                v-bind:key="index"
              >
                {{ error }}
              </b-form-invalid-feedback>
            </b-form-group>
          </ValidationProvider>
        </b-col>
        <b-col md="2">
          <ValidationProvider
            name="Платник ПЕП"
            v-slot="{ errors, dirty, validated, valid, changed }"
            vid="payer_is_public_where"
          >
            <b-form-group
              label="Платник ПЕП"
              label-size="sm"
              label-class="font-weight-bold m-0"
            >
              <b-form-select
                :value="propPayerIsPublic"
                @input="changeFilter('propPayerIsPublic', $event)"
                :options="isPublicTypes"
                text-field="name"
                value-field="value"
                :state="
                  setValidationState(errors, dirty, validated, valid, changed)
                "
              />
              <b-form-invalid-feedback
                :state="errors.length === 0"
                v-for="(error, index) in errors"
                v-bind:key="index"
              >
                {{ error }}
              </b-form-invalid-feedback>
            </b-form-group>
          </ValidationProvider>
        </b-col>
        <b-col>
          <ValidationProvider
            name="Хеш картки отримувача"
            v-slot="{ errors, dirty, validated, valid, changed }"
            vid="payee_card_hash_where"
            rules="max:255"
          >
            <b-form-group
              label-size="sm"
              label-class="font-weight-bold m-0"
              label="Хеш картки отримувача"
            >
              <b-form-input
                :value="propPayeeCardHash"
                @input="changeFilter('propPayeeCardHash', $event)"
                trim
                :state="
                  setValidationState(errors, dirty, validated, valid, changed)
                "
              />
              <b-form-invalid-feedback
                :state="errors.length === 0"
                v-for="(error, index) in errors"
                v-bind:key="index"
              >
                {{ error }}
              </b-form-invalid-feedback>
            </b-form-group>
          </ValidationProvider>
        </b-col>
        <b-col md="2">
          <ValidationProvider
            name="Отримувач ПЕП"
            v-slot="{ errors, dirty, validated, valid, changed }"
            vid="payee_is_public_where"
          >
            <b-form-group
              label="Отримувач ПЕП"
              label-size="sm"
              label-class="font-weight-bold m-0"
            >
              <b-form-select
                :value="propPayeeIsPublic"
                @input="changeFilter('propPayeeIsPublic', $event)"
                :options="isPublicTypes"
                text-field="name"
                value-field="value"
                :state="
                  setValidationState(errors, dirty, validated, valid, changed)
                "
              />
              <b-form-invalid-feedback
                :state="errors.length === 0"
                v-for="(error, index) in errors"
                v-bind:key="index"
              >
                {{ error }}
              </b-form-invalid-feedback>
            </b-form-group>
          </ValidationProvider>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <ValidationProvider
            name="термінал"
            v-slot="{ errors, dirty, validated, valid, changed }"
            vid="terminal_name_ilike"
            rules="max:255"
          >
            <b-form-group
              label-size="sm"
              label-class="font-weight-bold m-0"
              label="Термінал"
            >
              <b-form-input
                :value="propTerminalName"
                @input="changeFilter('propTerminalName', $event)"
                trim
                :state="
                  setValidationState(errors, dirty, validated, valid, changed)
                "
              />
              <b-form-invalid-feedback
                :state="errors.length === 0"
                v-for="(error, index) in errors"
                v-bind:key="index"
              >
                {{ error }}
              </b-form-invalid-feedback>
            </b-form-group>
          </ValidationProvider>
        </b-col>
        <b-col>
          <b-row>
            <b-col>
              <ValidationProvider
                name="Сума платежу від"
                v-slot="{ errors, dirty, validated, valid, changed }"
                vid="amount_gte"
                :rules="{ regex: /(^\d{1,6}([.]\d{1,4})?)$/ }"
              >
                <b-form-group
                  label-size="sm"
                  label-class="font-weight-bold m-0"
                  label="Сума платежу"
                  description="Сума від"
                >
                  <b-form-input
                    type="number"
                    min="0"
                    step="0.01"
                    :value="propAmountFrom"
                    @input="changeFilter('propAmountFrom', $event)"
                    trim
                    :state="
                      setValidationState(
                        errors,
                        dirty,
                        validated,
                        valid,
                        changed
                      )
                    "
                  />
                  <b-form-invalid-feedback
                    :state="errors.length === 0"
                    v-for="(error, index) in errors"
                    v-bind:key="index"
                  >
                    {{ error }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </ValidationProvider>
            </b-col>
            <b-col>
              <ValidationProvider
                name="Сума платежу до"
                v-slot="{ errors, dirty, validated, valid, changed }"
                vid="amount_lte"
                :rules="{ regex: /(^\d{1,6}([.]\d{1,4})?)$/ }"
              >
                <b-form-group
                  label-size="sm"
                  label-class="font-weight-bold"
                  description="Сума до"
                  style="padding-top: 23px"
                >
                  <b-form-input
                    type="number"
                    min="0"
                    step="0.01"
                    :value="propAmountTo"
                    @input="changeFilter('propAmountTo', $event)"
                    trim
                    :state="
                      setValidationState(
                        errors,
                        dirty,
                        validated,
                        valid,
                        changed
                      )
                    "
                  />
                  <b-form-invalid-feedback
                    :state="errors.length === 0"
                    v-for="(error, index) in errors"
                    v-bind:key="index"
                  >
                    {{ error }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </ValidationProvider>
            </b-col>
          </b-row>
        </b-col>
        <b-col>
          <ValidationProvider
            name="За період дати вставки"
            v-slot="{ errors, dirty, validated, valid, changed }"
            vid="created_at_gte"
          >
            <b-form-group
              label-size="sm"
              label-class="font-weight-bold m-0"
              label="За період дати вставки"
            >
              <date-range-picker
                :start-date="propCreatedAtFrom"
                :end-date="propCreatedAtTo"
                v-on:start-date-change="changeCreatedAtFrom"
                v-on:end-date-change="changeCreatedAtTo"
                :state="
                  setValidationState(errors, dirty, validated, valid, changed)
                "
                style="width: 100%"
              />
              <b-form-invalid-feedback
                :state="errors.length === 0"
                v-for="(error, index) in errors"
                v-bind:key="index"
              >
                {{ error }}
              </b-form-invalid-feedback>
            </b-form-group>
          </ValidationProvider>
        </b-col>
        <b-col>
          <ValidationProvider
            name="Валюта платежу"
            v-slot="{ errors, dirty, validated, valid, changed }"
            vid="currency_where"
          >
            <b-form-group
              label-size="sm"
              label-class="font-weight-bold m-0"
              label="Валюта платежу"
            >
              <b-form-select
                text-field="name"
                :value="propCurrency"
                @input="changeFilter('propCurrency', $event)"
                :options="$stringConstants('CURRENCY_LIST')"
                :state="
                  setValidationState(errors, dirty, validated, valid, changed)
                "
              >
                <template #first>
                  <b-form-select-option value="" />
                </template>
              </b-form-select>
              <b-form-invalid-feedback
                :state="errors.length === 0"
                v-for="(error, index) in errors"
                v-bind:key="index"
              >
                {{ error }}
              </b-form-invalid-feedback>
            </b-form-group>
          </ValidationProvider>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="6">
          <ValidationProvider
            name="Країна"
            v-slot="{ errors, dirty, validated, valid, changed }"
            vid="country_where"
          >
            <b-form-group
              label-size="sm"
              label-class="font-weight-bold m-0"
              label="Країна"
            >
              <b-form-select
                :value="propCountry"
                @input="changeFilter('propCountry', $event)"
                :state="
                  setValidationState(errors, dirty, validated, valid, changed)
                "
              >
                <option value=""></option>
                <option
                  v-for="option in countryList"
                  :key="option.id"
                  :value="option.name['alfa-code3']"
                >
                  {{ option.name["alfa-code3"] }}
                </option>
              </b-form-select>
              <b-form-invalid-feedback
                :state="errors.length === 0"
                v-for="(error, index) in errors"
                v-bind:key="index"
              >
                {{ error }}
              </b-form-invalid-feedback>
            </b-form-group>
          </ValidationProvider>
        </b-col>
      </b-row>
      <b-row class="pb-3">
        <b-col>
          <b-button variant="primary" @click="clearFilters">
            Очистити
          </b-button>
        </b-col>
        <b-col>
          <b-list-group horizontal="md" class="text-center justify-content-end">
            <b-list-group-item
              class="text-center border-0"
              :class="totalItems > 0 ? 'bg-primary text-white' : 'bg-secondary'"
              :style="totalItems > 0 ? '' : 'opacity: 0.65'"
            >
              <div
                class="d-flex justify-content-center align-items-center h-100"
              >
                Загальна кількість:
                <b-badge variant="light" class="ml-1">{{ totalItems }}</b-badge>
              </div>
            </b-list-group-item>
            <b-list-group-item class="p-0 border-0">
              <b-button
                :variant="totalItems > 0 ? 'primary' : 'secondary'"
                :disabled="!totalItems > 0 || isSubmittingCalculate"
                @click="getTotalSum"
                class="rounded-0 h-100 info-amount-block"
                v-if="totalSum.length === 0"
              >
                <div v-if="!isSubmittingCalculate">
                  <b-icon-wallet2 scale="0.75" />
                  Порахувати загальну суму
                </div>
                <b-spinner small type="grow" v-else>Завантаження</b-spinner>
              </b-button>
              <div
                v-else
                class="d-flex justify-content-center align-items-center bg-primary h-100 info-amount-block pb-1"
              >
                <div class="text-right">
                  <div v-for="item in totalSum" :key="item.currency">
                    <b-badge
                      class="badge-style"
                      variant="light"
                      :data-text="item.amount | amount"
                      @click.stop.prevent="copyItem"
                    >
                      {{ item.currency + ": " }}
                      {{ item.amount | amount }}
                    </b-badge>
                  </div>
                </div>
                <div class="text-left">
                  <div v-for="item in totalSum" :key="item.currency">
                    <b-badge
                      variant="light"
                      class="badge-style ml-1"
                      :data-text="item.count"
                      @click.stop.prevent="copyItem"
                    >
                      {{ "к-сть: " + item.count }}
                    </b-badge>
                  </div>
                </div>
              </div>
            </b-list-group-item>
            <b-list-group-item class="p-0 border-0">
              <b-button
                v-b-modal.export-operations-modal
                variant="success"
                class="h-100 btn-export"
              >
                <b-icon-download scale="0.75" />
                Експорт
              </b-button>
            </b-list-group-item>
          </b-list-group>
        </b-col>
      </b-row>
    </b-container>
  </ValidationObserver>
</template>

<script>
import DateRangePicker from "@/components/DateRangePickerWrapper";
import { mapGetters } from "vuex";
import Multiselect from "vue-multiselect";
import mixins from "@/mixins";

export default {
  name: "OperationListFilter",
  props: {
    isSubmitting: {
      type: Boolean,
      required: true,
    },
    isSubmittingCalculate: {
      type: Boolean,
      required: true,
    },
    totalItems: {
      type: Number,
      required: true,
    },
    totalSum: {
      type: Array,
      required: true,
    },
    propProcessedAtFrom: {
      type: Date,
      required: true,
    },
    propProcessedAtTo: {
      type: Date,
      required: true,
    },
    propPaysystemOperationId: {
      type: String,
      required: true,
    },
    propReceivedAtFrom: {
      type: Date,
    },
    propReceivedAtTo: {
      type: Date,
    },
    propId: {
      type: String,
      required: true,
    },
    propFinancialModelId: {
      type: String,
      required: true,
    },
    propNbuPaysystemCode: {
      type: String,
      required: true,
    },
    propOperationType: {
      type: Array,
      required: true,
    },
    propOperationDestinationCode: {
      type: Array,
      required: true,
    },
    propPayer: {
      type: String,
      required: true,
    },
    propPayerCardHash: {
      type: String,
      required: true,
    },
    propPayerContractNumber: {
      type: String,
      required: true,
    },
    propPayee: {
      type: String,
      required: true,
    },
    propPayeeCardHash: {
      type: String,
      required: true,
    },
    propPayeeContractNumber: {
      type: String,
      required: true,
    },
    propTerminalName: {
      type: String,
      required: true,
    },
    propAmountFrom: {
      type: [String, Number],
      required: true,
    },
    propAmountTo: {
      type: [String, Number],
      required: true,
    },
    propCreatedAtFrom: {
      type: Date,
    },
    propCreatedAtTo: {
      type: Date,
    },
    propCurrency: {
      type: String,
      required: true,
    },
    propCountry: {
      type: String,
      required: true,
    },
    propPayerCounterpartId: {
      type: Array,
      required: true,
    },
    propPayeeCounterpartId: {
      type: Array,
      required: true,
    },
    propPayerCardMask: {
      type: String,
      required: true,
    },
    propPayeeCardMask: {
      type: String,
      required: true,
    },
    propPayeeIsPublic: {
      type: Boolean | String,
      required: true,
    },
    propPayerIsPublic: {
      type: Boolean | String,
      required: true,
    },
    propErrors: {
      type: Object,
      default: () => {},
    },
  },
  components: { DateRangePicker, Multiselect },
  mixins: [mixins],
  data: () => {
    return {
      isPublicTypes: [
        {
          name: "",
          value: "",
        },
        {
          name: "Так",
          value: true,
        },
        {
          name: "Ні",
          value: false,
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      meFinancialModelList: "auth/meFinancialModels",
      operationNbuPaysystemList: "dictionary/allOperationNbuPaysystemList",
      paymentMethodList: "dictionary/allContractPaymentMethods",
      operationDestinationCodeList: "dictionary/allOperationDestinationCode",
      countryList: "dictionary/allCountries",
      counterparts: "contracts/getAllCounterparts",
    }),
  },
  methods: {
    validate() {
      this.$nextTick();

      return new Promise((resolve) => {
        this.$refs.formFilterValidation.validate().then((r) => {
          resolve(r);
        });
      });
    },
    changeFilter(name, value) {
      this.$emit("filter-change", [name, value]);
    },
    clearFilters() {
      this.$emit("filter-clear");
      requestAnimationFrame(() => {
        this.$refs.formFilterValidation.reset();
      });
    },
    copyItem(e) {
      navigator.clipboard.writeText(e.currentTarget.getAttribute("data-text"));
      this.$snotify.success("Скопійовано в буфер обміну");
    },
    getTotalSum() {
      this.$emit("total-sum");
    },
    changeProcessedAtFrom: function (value) {
      this.changeFilter("propProcessedAtFrom", value);
    },
    changeProcessedAtTo: function (value) {
      this.changeFilter("propProcessedAtTo", value);
    },
    changeReceivedAtFrom: function (value) {
      this.changeFilter("propReceivedAtFrom", value);
    },
    changeReceivedAtTo: function (value) {
      this.changeFilter("propReceivedAtTo", value);
    },
    changeCreatedAtFrom: function (value) {
      this.changeFilter("propCreatedAtFrom", value);
    },
    changeCreatedAtTo: function (value) {
      this.changeFilter("propCreatedAtTo", value);
    },
    customDestinationCodeLabel(id) {
      const code = this.operationDestinationCodeList.find((e) => e.id === id);
      return `(${code.value}) ${code.name}`;
    },
    tooltipDestinationCode(id) {
      return "Активний по " + this.destinationCodeHasEndedAtDate(id);
    },
    destinationCodeHasEndedAtDate(id) {
      const code = this.operationDestinationCodeList.find((e) => e.id === id);
      return code.ended_at;
    },
    customCounterpartNameLabel(id) {
      const counterpart = this.counterparts.find((e) => e.id === id);
      return `${counterpart.name} — [${counterpart.financial_model.name}] - [${counterpart.code}]`;
    },
    customPaymentMethodLabel(id) {
      return this.paymentMethodList.find((e) => e.id === id).name;
    },
  },
  watch: {
    propErrors: {
      deep: true,
      handler: function (errors) {
        if (errors.code === 400) {
          this.$refs.formFilterValidation.setErrors(errors.description);
        }
      },
    },
  },
};
</script>

<style scoped>
.badge-style {
  cursor: pointer;
}

.info-amount-block {
  min-width: 227px;
}

.btn-export {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.btn-primary.disabled {
  background-color: #20a8d8;
  opacity: 1;
}
</style>
